
import Navbar from '@/components/Navbar/NavbarPure.vue'
import ethService from '@/services/eth'
import Web3 from 'web3'
const BN = Web3.utils.BN;

export default {
  components: {
    Navbar
  },
  data() {
    return {
      dataLoading: false,
      checkHaveMap:  '',
      wallet: {
        account: '',
        balance: ''
      },
      pathData: {
        amount: '',
        path: [],
        address: ''
      },
      claim: {
        total: '',
        released: '',
        unlockedAmount: ''
      },
      distributeDPRLoading: false,
      claimLoading: false,
      disablePage: true,
      lockData: {

      },
      chooseLoading: false
     
    }
  },
  async mounted() {
    await ethService.init({
      onAccountsChanged: (accounts) => {
        this.disablePage = false
        this.wallet.account = accounts[0]
        this.getData()
      }
    }).catch(e => {
      console.log(e)
    })
    this.getData()
  },
  methods: {
    toClaim() {
      this.chooseLoading = true
      ethService.released.distributeAndLock(this.pathData.amount, this.pathData.path, false, this.wallet.account).then(() => {
        this.getData()
      }).catch(e => {
        this.$Dialog.confirm({
          message: JSON.stringify(e.message)
        })
      }).finally(() => {
        this.chooseLoading = false
      })
    },
    toLock() {
      this.chooseLoading = true
      ethService.released.distributeAndLock(this.pathData.amount, this.pathData.path, true, this.wallet.account).then(() => {
        this.getData()
      }).catch(e => {
        this.$Dialog.confirm({
          message: JSON.stringify(e.message)
        })
      }).finally(() => {
        this.chooseLoading = false
      })
    },
    async getPath() {
      const account = this.wallet.account
      return this.$cFetch({
        headers: {
          'mock-res-index': 1
        },
        url: `/tokenClaim/getAccountWithPath3/${account}`,
        method: 'GET'
      }).then(res => {
        if (res.data.success) {
          this.disablePage = false
          let amountStr = res.data.amount.toString().split('.')
          if (!amountStr[1]) {
            amountStr.push('0')
          }
          this.pathData.amount = new BN(`${amountStr[0]}${amountStr[1]}`).mul(new BN(10).pow(new BN(18 - amountStr[1].length))).toString(10)
          this.pathData.path = res.data.path;
          this.pathData.address = res.data.address;
          return
        }
        this.disablePage = true
        this.$Dialog.alert({
          message: this.$t('claim.addressNotInList'),
          theme: 'round-button'
        }).then(() => {
          // on close
        });
      })
    },
    async getData() {
      this.dataLoading = true
      let accounts = await ethService.getAccount().catch(e => {
        console.log(e)
        this.$Notify({
          type: 'danger',
          message: e.message
        })
      })
      this.wallet.account = accounts[0]
      await this.getPath()
      if (!this.pathData.path.length) {
        this.dataLoading = false
        return
      }
      let balance = await ethService.getBalance(this.wallet.account)
      this.wallet.balance = (balance/1e18).toFixed(8)/1;
      this.lockData = await ethService.releasedLock.getData(this.wallet.account)
      console.log(this.lockData)
      this.dataLoading = false;
    },
    async claimDPR() {
      this.claimLoading = true
      let result = await ethService.releasedLock.claim(this.wallet.account).catch(e => {
        this.$Dialog.confirm({
          message: JSON.stringify(e.message)
        })
      })
      this.claimLoading = false
      if (result.blockHash) {
        this.$toast.success("success")
        this.getData()
      }
      console.log(result)
    }
  }

}
