
import Vue from 'vue'
export default {
  props: {
    navTitle: {
      type: String,
      default: "DEEPER NETWORK"
    }
  },
  data() {
    return {
      show: false,
      haveLogin: false
    }
  },
  computed: {
    lang() {
      return Vue.prototype.$$i18n.locale
    }
  }
}
